import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useFirebaseAuth } from "./firebaseContext";
import { isAuthenticated } from "./idp";
import microsoftLogo from "../ms-logo.svg";

export default function LoginPage() {
  const { microsoftSignIn, user } = useFirebaseAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    setError(null);
    try {
      await microsoftSignIn();
    } catch (err) {
      setError("Login failed");
    } finally {
      setLoading(false);
      if (user?.uid) {
        navigate("../clinics");
      }
    }
  };

  useEffect(() => {
    if (user?.uid) {
      if (isAuthenticated()) {
        navigate("../clinics");
      }
    }
  }, [navigate, user]);

  return (
    <>
      <div className="container">
        <div className="login-form-container">
          <div className="di-logo">
            <img
              src="https://di-static-assets.azureedge.net/images/di_logo.svg"
              alt="DI Logo"
            />
          </div>
          <div className="welcome-text">Centralized Admin Panel.</div>
          <div className="login-button-container">
            <button
              type="button"
              onClick={handleLogin}
              disabled={loading}
              className="login-button"
            >
              {loading ? (
                "Logging in..."
              ) : (
                <>
                  <img
                    src={microsoftLogo}
                    alt="Microsoft Logo"
                    className="microsoft-logo"
                  />
                  Login with Microsoft
                </>
              )}
            </button>
            {error && <p>{error}</p>}
          </div>
        </div>
      </div>
    </>
  );
}
