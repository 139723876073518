/* eslint-disable */
import { useContext, createContext, useEffect, useState } from "react";
import {
  OAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import auth from "./firebase";
import { addSeconds } from "date-fns";
import {
  SESSION_EXPIRES_SECONDS,
  TOKEN_EXPIRES_SECONDS,
  MICROSOFT_SIGN_IN_TENANT,
} from "../config";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});

  const microsoftSignIn = () => {
    const provider = new OAuthProvider("microsoft.com");
    provider.setCustomParameters({
      tenant: `${MICROSOFT_SIGN_IN_TENANT}`,
    });
    signInWithPopup(auth, provider);
  };

  const logOut = () => {
    signOut(auth);
    localStorage.removeItem("auth");
  };

  useEffect(() => {
    const onAuthStateChangedSubscribe = onAuthStateChanged(
      auth,
      async (currentUser) => {
        const idTokenResult = await currentUser?.getIdTokenResult();
        setUser(currentUser);

        if (currentUser?.uid) {
          localStorage.setItem(
            "auth",
            JSON.stringify({
              id: currentUser.uid,
              access_token: currentUser.accessToken,
              token_type: "Bearer",
              display_name: currentUser.displayName,
              email: currentUser.email,
              expires: addSeconds(
                new Date(),
                parseInt(`${SESSION_EXPIRES_SECONDS}`, 10)
              ).valueOf(),
              access_token_expires: addSeconds(
                new Date(),
                parseInt(`${TOKEN_EXPIRES_SECONDS}`, 10)
              ).valueOf(),
              super_admin: idTokenResult?.claims?.SuperAdmin == "true",
            })
          );
        }
      }
    );
    return () => {
      onAuthStateChangedSubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ microsoftSignIn, logOut, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useFirebaseAuth = () => {
  return useContext(AuthContext);
};
