import { addSeconds, isBefore } from "date-fns";
import { signOut } from "firebase/auth";
import auth from "./firebase";
import { SESSION_EXPIRES_SECONDS, TOKEN_EXPIRES_SECONDS } from "../config";

function isExpired(expires: number): boolean {
  return isBefore(expires, new Date());
}

export interface AuthResponse {
  id: string;
  access_token: string;
  access_token_expires: number;
  expires: number;
  token_type: string;
  display_name: string;
  email: string;
  super_admin: boolean;
}

export function getAuthResponse(): AuthResponse | undefined {
  const storedAuthResponse = localStorage.getItem("auth");
  if (!storedAuthResponse) return undefined;

  const authResponse: AuthResponse = JSON.parse(storedAuthResponse);

  if (!authResponse.id) return undefined;

  if (!authResponse.super_admin) return undefined;

  if (isExpired(authResponse.expires)) {
    signOut(auth);
    localStorage.removeItem("auth");
    return undefined;
  }

  return authResponse;
}

export async function getAccessToken(): Promise<string | undefined> {
  const authResponse = getAuthResponse();

  if (!authResponse) return undefined;

  if (isExpired(authResponse.access_token_expires)) {
    const newAccessToken = await auth.currentUser?.getIdToken();

    localStorage.setItem(
      "auth",
      JSON.stringify({
        id: authResponse.id,
        access_token: newAccessToken,
        token_type: "Bearer",
        display_name: authResponse.display_name,
        email: authResponse.email,
        expires: addSeconds(
          new Date(),
          parseInt(`${SESSION_EXPIRES_SECONDS}`, 10)
        ).valueOf(),
        access_token_expires: addSeconds(
          new Date(),
          parseInt(`${TOKEN_EXPIRES_SECONDS}`, 10)
        ).valueOf(),
        super_admin: authResponse.super_admin,
      })
    );

    return newAccessToken;
  }

  return authResponse?.access_token;
}

interface AccessTokenPayload {
  sub: string;
  SuperAdmin?: string;
}

export function getAccessTokenPayload(): AccessTokenPayload | undefined {
  const authResponse = getAuthResponse();
  if (!authResponse) return undefined;

  const accessTokenPayload: AccessTokenPayload = {
    sub: authResponse.id,
    SuperAdmin: authResponse.super_admin ? "true" : "false",
  };

  return accessTokenPayload;
}

interface IdTokenPayload {
  sub: string;
  given_name: string;
  family_name: string;
}

export function getIdTokenClaims(): IdTokenPayload | undefined {
  const authResponse = getAuthResponse();
  if (!authResponse) return undefined;

  const splitDisplayName = authResponse.display_name.split(" ");
  const givenName = splitDisplayName.length > 0 ? splitDisplayName[0] : "";
  const familyName = splitDisplayName.length > 1 ? splitDisplayName[1] : "";

  return {
    sub: authResponse.id,
    given_name: givenName,
    family_name: familyName,
  };
}

export function isAuthenticated(): boolean {
  const accessTokenPayload = getAccessTokenPayload();
  return accessTokenPayload?.SuperAdmin === "true";
}

export function logout(): void {
  signOut(auth);
  localStorage.removeItem("auth");
}
