import { AuthProvider, UserIdentity } from "react-admin";
import { getIdTokenClaims, isAuthenticated, logout } from "./idp";

const authProvider: AuthProvider = {
  async login() {
    // We don't have a login form
    return Promise.reject();
  },
  async logout() {
    if (isAuthenticated()) {
      logout();
    }
  },
  async checkAuth() {
    if (isAuthenticated()) {
      return Promise.resolve();
    }
    return Promise.reject();
  },
  async checkError(error: any) {
    if (error?.status === 401) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  async getIdentity(): Promise<UserIdentity> {
    const claims = getIdTokenClaims();
    if (!claims) return Promise.reject();

    return {
      id: claims.sub,
      fullName: `${claims.given_name} ${claims.family_name}`,
    };
  },
  getPermissions() {
    // No permissions to return
    return Promise.resolve({});
  },
};

export default authProvider;
